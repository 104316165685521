.ReaderControls {
    background: rgb(35,35,35);
    display: flex;
    flex: 1;
    z-index: 10;
    max-height: 64px;
    width: 100vw;
}
.ReaderControls-Navigation {
    flex: 1;
}
.ReaderControls-Content {
    flex: 1;
    display: flex;
}
.ReaderControls-Icon {

}

.ReaderControls-PlaceHolder {
    width: 48px;
    visibility: hidden;
}

.ReaderControls-Control {
    display: flex;
}
.ReaderControls-Chapter-Control {
    align-self: center;
}