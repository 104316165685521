html.reading .Footer {
    display: none;
}


html:not(.reading) .Footer-Container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
}
html:not(.reading) .Footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-transform: uppercase;
    font-size: 2em;
    text-align: center;
}

html:not(.reading) .Footer a {
    max-width: 1em;
    margin-right: 1em;
    margin-bottom: 5vh;
}
html:not(.reading) .Footer img {
    width: 1em;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
}