.Reader {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;

}

.Reader .Reader-Character-Image-Container {
    display: flex;
    flex-direction: column;
    align-items: start;
    flex: 1;
}

html.reading:not(.viewing-images) .Reader {
    overflow: hidden;
}

html.reading.viewing-images .Reader,
html.reading.viewing-images .Reader .Reader-Character-Image-Container
{
    background: rgb(35,35,35);
}

.Reader-Iframe {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex: 1;
}

.Reader .Reader-Character-Image {
    flex: 1;
    max-width: 80vw;
    max-height: 80vh;
}