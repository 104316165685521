
.Home-Background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -10;

    background: rgb(35,35,35);
    /*background: -moz-radial-gradient(circle, rgba(112,112,112,0.8) 8%, rgba(35,35,35,1) 45%);*/
    /*background: -webkit-radial-gradient(circle, rgba(112,112,112,0.8) 8%, rgba(35,35,35,1) 45%);*/
    /*background: radial-gradient(circle, rgba(112,112,112,0.8) 8%, rgba(35,35,35,1) 45%);*/
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c2c2c2",endColorstr="#010101",GradientType=1);*/
}

.Home-Logo {
    position: fixed;

    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    z-index: -9;

    background:  50% 50% no-repeat url('/src/Images/logo.png') ;
    background-size: 35vw 35vw;
}

@media(min-width: 500px) and (max-width: 800px) {
    .Home-Logo {
        background-size: 86.5vw 86.5vw;
    }
    .Home-Background {
        /*background: -moz-radial-gradient(circle, rgba(112,112,112,0.8) 8%, rgba(35,35,35,1) 45%) !important;*/
        /*background: -webkit-radial-gradient(circle, rgba(112,112,112,0.8) 8%, rgba(35,35,35,1) 45%) !important;*/
        /*background: radial-gradient(circle, rgba(112,112,112,0.8) 8%, rgba(35,35,35,1) 45%);*/
    }
}

@media (min-width: 800px) and (max-width: 1200px) {
    .Home-Logo {
        background-size: 65.8vw 65.8vw;
    }
    .Home-Background {
        /*background: -moz-radial-gradient(circle, rgba(112,112,112,0.8) 8%, rgba(35,35,35,1) 50%) !important;*/
        /*background: -webkit-radial-gradient(circle, rgba(112,112,112,0.8) 8%, rgba(35,35,35,1) 50%) !important;*/
        /*background: radial-gradient(circle, rgba(112,112,112,0.8) 8%, rgba(35,35,35,1) 50%);*/
    }
}


@media (min-width: 1200px) {
    .Home-Logo {
        background-size: 37.6vw 37.6vw;
    }
    .Home-Background {
        /*background: -moz-radial-gradient(circle, rgba(112,112,112,0.8) 2%, rgba(35,35,35,1) 33%) !important;*/
        /*background: -webkit-radial-gradient(circle, rgba(112,112,112,0.8) 2%, rgba(35,35,35,1) 33%) !important;*/
        /*background: radial-gradient(circle, rgba(112,112,112,0.8) 2%, rgba(35,35,35,1) 33%);*/
    }
}

@media (max-width: 500px) {
    .Home-Logo {
        background-size: 71.5vw 71.5vw;
    }
    .Home-Background {
        /*background: -moz-radial-gradient(circle, rgba(112,112,112,0.8) 8%, rgba(35,35,35,1) 55%) !important;*/
        /*background: -webkit-radial-gradient(circle, rgba(112,112,112,0.8) 8%, rgba(35,35,35,1) 55%) !important;*/
        /*background: radial-gradient(circle, rgba(112,112,112,0.8) 8%, rgba(35,35,35,1) 55%);*/
    }
}
