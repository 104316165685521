.Error {
    display: flex;
    flex-direction: column;
    flex: 1;
}

html.reading:not(.viewing-images) .Error {
    overflow: hidden;
}

html.reading.viewing-images .Error
{
    background: rgb(35,35,35);
}

.Error-Text {
    font-size: 1.5rem;
}
