.BarcodeGenerator {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 1.5em;

}
.BarcodeGenerator-Container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.BarcodeGenerator input {
    margin: 0.5em;
}
