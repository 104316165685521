.Listen .rhap_container {
    background: none;
}
.Listen .rhap_time, .Listen .rhap_total-time {
    color: white;
}

.Listen svg {
    width: 0.7em;
    height: 0.7em;
    color: white;
}

.AudioPlayer-Header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AudioPlayer-Footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.AudioPlayer-Footer-Text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AudioPlayer-Chapter {
    font-size: 1.2rem;
    line-height: 3rem;
}

.Listen-Chapters-Icon {
    font-size: 3em;
}

.Listen-Chapters-Icon {
    margin-right: 8px;
}
.Listen-Chapters-Text {
    font-size: 1.2rem;
}
.AudioPlayer-Footer button {
    background-color: #202020;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1em;
    display: flex;
    align-items: center;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.AudioPlayer-Footer button:hover {
    background-color: #333333;
}


.ChaptersPanel {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #202020;
    color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure it's above other content */
    padding: 20px;
    box-sizing: border-box;
    font-size: 1.5em;
    width: 90vw;
    height: 80vh;
    text-align: center;
    border: 1px groove rgba(255,255,255,0.5);
    overflow: scroll;
}



.ChaptersPanel h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.ChaptersPanel div {
    cursor: pointer;
    padding: 10px 0;
    border-bottom: 1px solid #333;
    font-size: 1.5rem
}

.ChaptersPanel div:last-child {
    border-bottom: none;
}

.ChaptersPanel button {
    position: absolute;
    top: 10px;
    right: 20px;
    background: none;
    border: none;
    color: white;
    font-size: 1.3rem;
    cursor: pointer;
}

/*.LoadingOverlay {*/
/*    position: fixed;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    background-color: rgba(0, 0, 0, 0.5); !* Semi-transparent background *!*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    z-index: 2000; !* Ensure it's above all other content *!*/
/*    color: white;*/
/*    font-size: 2em; !* Large text *!*/
/*}*/

.LoadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2000; /* Ensure it's above all other content */
    color: white;
    font-size: 2em; /* Large text */
}

