.Footer-Container .rhap_volume-controls,
.Footer-Container .rhap_progress-section{
    display: none;
}

.reading .AudioPlayer {
    display: none;
}

.Footer-Container .rhap_controls-section {
    display: block;
}
.Footer-Container .rhap_container {
    background-color: transparent;
    width: auto;
    padding: 0px;
    margin-top: -2em;
    box-shadow: none;
}
.Footer-Container .rhap_stacked .rhap_controls-section {
    margin: 0px;
}

.AudioPlayer svg {
    width: 0.7em;
    height: 0.7em;
    color: white;
}