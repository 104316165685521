.About {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2em;
    align-items: center;
}

.About-Image {
    max-width: 60vw;
    max-height: 60vw;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: row;
    flex: 1;
}

.About h2 {
    margin: 0px;
}
.About-Text {
    flex: 2;
    margin: 0;
    margin-left: 0.5em;
    font-size: 1rem;
    margin-top: 1.5em;
}


@media (max-width: 800px) {
    .About-Image {
        max-width: 60vw;
    }

    .About-Text {
        font-size: 1.5rem;
    }
}

@media (min-width: 800px) and (max-width: 1200px) {
    .About-Image {
        max-width: 35vw;
    }
    .About-Text {
        font-size: 1.5rem;
    }
}


@media (min-width: 1200px) {
    .About-Image {
        max-width: 20vw;
    }
    .About-Text {
        font-size: 1.5rem;
    }
}
