.Dashboard {
    display: grid;
    grid-template-columns: auto;

}


@media (max-width: 800px) {
    .Dashboard-Text {
        font-size: 1.5rem !important;;
    }
}

@media (min-width: 800px) and (max-width: 1200px) {
    .Dashboard-Text {
        font-size: 1.5rem !important;
    }
}


@media (min-width: 1200px) {
    .Dashboard-Text {
        font-size: 1.5rem !important;;
    }
}