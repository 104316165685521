

.App-Content {
    overflow: scroll;
}
html:not(.reading) .App-Content {
    margin-top: 5vh;
    height: 100%;
}

.App {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: space-between;
}

html.reading .App {
    width: 100vw;
    height: 100vh;
    display: flex;
}

html.reading:not(.viewing-images) .App-Content {
    overflow: hidden;
}

@media (max-width: 800px) {
    html:not(.reading) .App {
        width: 90vw;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (min-width: 800px) and (max-width: 1200px) {
    html:not(.reading) .App {
        width: 70vw;
        margin-left: auto;
        margin-right: auto;
    }
}


@media (min-width: 1200px) {
    html:not(.reading) .App {
        width: 40vw;
        margin-left: auto;
        margin-right: auto;
    }
}

html:not(.reading) iframe {
    z-index: -1 !important;
}

::-webkit-scrollbar {

}

::-webkit-scrollbar-track {
    /*background: #f0f0f0;*/
    background: rgb(35,35,35, 0.3);
}

::-webkit-scrollbar-thumb {
    background: rgb(35,35,35);
}