html.reading .Header {
    display: none;
}

html:not(.reading) .Header {
    margin-top: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-transform: uppercase;
    font-size: 2em;
    text-align: center;
}

html:not(.reading) .Header a {
    margin-right: 1em;
}

html:not(.reading) .Header-Active {
    font-weight: bold;
}


html:not(.reading) h1 {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
}

.Header-ReactSnap-Hidden-Link {
    visibility: hidden;
    position: absolute;
    left: -10000px;
    top: -100000px;
    width: 0px;
    height: 0px;
    z-index: -99999;
    display: inline;
}