.DashboardBook {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2em;
}

.DashboardBook-Cover {
    max-width: 12vw;
    flex: 1;
}

.DashboardBook-Listen {
    border: 1px solid gray;
    border-radius: 0.5em;
    padding: 1em;
    line-height: 1.5em;
    padding-bottom: 0.8em;
    font-size: 1rem;
}

.DashboardBook-Column {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.DashboardBook-Right {
    margin-bottom: 1em;
    margin-left: 0.5em;
}

.DashboardBook h2 {
    margin: 0px;
}

.DashboardBook-Text {
    flex: 2;
    margin: 0;
    margin-left: 0.5em;
    font-size: 1rem;
}
