.EmailCapture {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    font-size: 1.5em;
}
.EmailCapture-Container {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.EmailCapture input {
    flex: 1;
    height: 80%;
    font-size: 3rem;
    text-align: center;
    border-radius: 1em;
    margin: 0.5em;
}

.EmailCapture button {
    flex: 8;
    height: 20%;
    font-size: 3rem;
    text-align: center;
    border-radius: 1em;
    margin: 0.5em;
}

.EmailCapture-Save {
    align-items: start !important;
}

