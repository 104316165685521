.ZenMode .Header,
.ZenMode .Footer-Container {
    display: none !important;
}

html:not(.reading) .ZenMode .App {
    width: 100vw !important;
    margin-left: auto;
    margin-right: auto;
}

html:not(.reading) .ZenMode .App-Content {
    margin-top: 0vh !important;
    height: 100vh !important;
}
